

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import {Khoa} from "@/models/khoa";
import KhamSucKhoeRepository from "@/services/KhamSucKhoeRepository";
import moment from "moment";
import {BacSiCreate} from "@/models/bacSiCreate";
import {BacSi} from "@/models/bacSi";
import {forEach} from "lodash";
import {FilterMatchMode} from "primevue/api";
import {UserUpdate} from "@/models/userUpdate";
import khamSucKhoeRepository from "@/services/KhamSucKhoeRepository";
import {useConfirm} from "primevue/useconfirm";


export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const bacSiCreate = ref({} as BacSiCreate);
    const listBacSi = ref([] as BacSi[]);
    const bacSiUpdate = ref({} as BacSi);
    const checkShow = ref(false);
    const confirm = useConfirm();

    const valid = computed(()=> bacSiCreate.value.chungChiHanhNghe && bacSiCreate.value.tenNhanVien);

    const validUpdate = computed(()=> bacSiUpdate.value.chungChiHanhNghe && bacSiUpdate.value.tenNhanVien);

    const createBacSi = () => {
     KhamSucKhoeRepository.createBacSi(bacSiCreate.value)
      .then((response) => {
        getListBacSi();
        toast.add({
          severity: 'success',
          summary: 'Thành công',
          detail: 'Thêm bác sĩ thành công vào hệ thống',
          life: 2000
        });
      })
      .catch(err => {
        toast.add({
          severity: 'error',
          summary: 'Lỗi',
          detail: err.response.data,
          life: 2000
        });
      });
    }

    const getListBacSi = () => {
      KhamSucKhoeRepository.getListBacSi()
          .then((response) => {
            listBacSi.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const getBacSiById = (id) => {
      listBacSi.value.forEach(x => {
        if(x.id == id) bacSiUpdate.value = x;
      })
      checkShow.value = true;
    }

    getListBacSi();

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const del = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Bạn có chắc muốn xoá thông tin bác sĩ này không?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-outlined',
        acceptLabel: 'Đồng ý',
        rejectLabel: 'Huỷ',
        accept: () => {
          khamSucKhoeRepository.deleteBacSi(bacSiUpdate.value.id)
              .then(response => {
                toast.add({severity:'info', summary:'Confirmed', detail:'Thông tin bác sĩ đã được xoá thành công', life: 3000});
                // router.push({
                //   name: 'home',
                // });
                const temp = ref({} as BacSi);
                bacSiUpdate.value = temp.value;
                checkShow.value = false;
                getListBacSi();
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail: err.response.data,
                  life: 2000
                })})
              .finally(()=>{
               });
        },
        reject: () => {
        }
      });
    };

    const doUpdate = () => {
      KhamSucKhoeRepository.updateBacSi(bacSiUpdate.value)
          .then((response) => {
            checkShow.value = false;
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Cập nhật bác sĩ thành công',
              life: 2000
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    return {
      bacSiCreate,
      valid,
      listBacSi,
      createBacSi,
      getBacSiById,
      filters,
      clearFilter,
      bacSiUpdate,
      validUpdate,
      del,
      doUpdate,
      checkShow
    }
  }
}

