

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import {Khoa} from "@/models/khoa";
import KhamSucKhoeRepository from "@/services/KhamSucKhoeRepository";
import moment from "moment";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listKhoa = ref([] as Khoa[]);
    const date = ref();

    const valid = computed(()=> register.value.password && register.value.username  && register.value.fullname && register.value.permission && register.value.idKhoa);

    KhamSucKhoeRepository.getListKhoa()
        .then((response) => {
          listKhoa.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    // if(!(store.state.permission == 'admin')){
    //   router.push({
    //     name: 'home'
    //   });
    // }

    register.value.permission = 'user';

    const userPermission = ref([
      {label: "Quản lý", value: "admin", param: 1},
      {label: "Nhân viên", value: "user", param: 2},
    ]);
    const doRegister = () => {
      if(register.value.password.length < 6)
      {
        toast.add({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Mật khẩu không hợp lệ, vui lòng nhập lại mật khẩu',
          life: 2000
        });
      }
      else {
        register.value.birthday = addHours(date.value);
        console.log("########$$$$$$$$$$$$$$$$$$$$$ debug register: " + JSON.stringify(register.value));
       // register.value.birthd
        AuthRepository.registerUser(register.value)
                .then((response) => {
                  toast.add({
                    severity: 'success',
                    summary: 'Đăng ký',
                    detail: 'Đăng ký thành viên thành công vào hệ thống',
                    life: 2000
                  });
                  router.push({
                    name: 'home'
                  });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
      };
    }

    const addHours = (time) => {
          return moment(time).format('YYYY-MM-DD hh:mm:ss');
          }

    return {
      register,
      doRegister,
      valid,
      userPermission,
      error,
      listKhoa,
      date
    }
  }
}

