import Repository from './Repository';
import {AxiosResponse} from "axios";
import {KhoaCreate} from "@/models/khoaCreate";
import {BacSiCreate} from "@/models/bacSiCreate";
import {BacSi} from "@/models/bacSi";

const resource = '/khamsuckhoe';

export default new class{
    createKhoa(khoaCreate: KhoaCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/themKhoa`, khoaCreate);
    }
    getListKhoa(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/danhSachKhoa`);
    }
    getListThongKe(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/danhSachThongKe`);
    }
    getTyLe(time1, time2): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/tyLe/${time1}/${time2}`);
    }
    getTyLeTrongThang(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/tyLeTrongThang`);
    }
    createBacSi(bacSiCreate: BacSiCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/createBacSi`, bacSiCreate);
    }
    updateBacSi(bacSi: BacSi): Promise<AxiosResponse<any>>{
        return Repository.put<any>(`${resource}/updateBacSi`, bacSi);
    }
    deleteBacSi(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/deleteBacSi/${id}`);
    }
    getListBacSi(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/listBacSi`);
    }
}
