
import {computed, ref} from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const defaultMenu = [
    ];
    const userMenu = [
      // {
      //   label:'Thêm tỷ lệ hao hụt',
      //   icon:'pi pi-fw pi-plus-circle',
      //   url: '/drugcreatepage'
      // },
      // {
      //   label:'Thực hiện bào chế thuốc',
      //   icon:'pi pi-fw pi-sync',
      //   url: '/detailpage'
      // },
      // {
      //   label:'Thống kê',
      //   icon:'pi pi-fw pi-chart-bar',
      //   url: '/thongKeSoLieu'
      // }
    ];
    const adminMenu = [
      // {
      //   label:'Thêm tỷ lệ hao hụt',
      //   icon:'pi pi-fw pi-plus-circle',
      //   url: '/drugcreatepage'
      // },
      // {
      //   label:'Thực hiện bào chế thuốc',
      //   icon:'pi pi-fw pi-sync',
      //   url: '/detailpage'
      // },
      // {
      //   label:'Thống kê ds thuốc bào chế',
      //   icon:'pi pi-fw pi-list',
      //   url: '/statisticalpage'
      // },
      {
        label:'Thống kê',
        icon:'pi pi-fw pi-chart-bar',
        url: '/thongKeSoLieu'
      },
      {
        label:'Bác sĩ',
        icon:'pi pi-fw pi-plus-circle',
        url: '/createbacsi'
      },
      {
        label:'Danh sách tài khoản',
        icon:'pi pi-fw pi-users',
        url: '/userlist'
      },
      {
        label:'Đăng ký tài khoản mới',
        icon:'pi pi-fw pi-user-plus',
        url: '/registerpage'
      }
    ];
    const appMenu = computed(() => {
      var md5 = require("md5");
      if(store.state.permission == md5("admin")) return adminMenu;
      else if(store.state.permission == md5("user")) return userMenu;
      else return defaultMenu;
    });
    const logout = () => {
      store.dispatch('clearToken');
      store.dispatch('clearPermission');
      router.push({
        name: 'login',
      });
      toast.add({
        severity:'success',
        summary: 'Đăng xuất',
        detail:'Đã xóa thông tin đăng nhập thành công',
        life: 1000
      });
    }

    return {
      appMenu,
      logout
    }
  }
}
