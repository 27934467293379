

import {computed, onMounted, ref} from 'vue'
import {useToast} from "primevue/usetoast";
import {FilterMatchMode} from "primevue/api";
import moment from "moment";
import {values} from "lodash";
import {ThongKe} from "@/models/thongKe";
import {TyLe} from "@/models/tyLe";
import khamSucKhoeRepository from "@/services/KhamSucKhoeRepository";


export default {
  setup: function () {
    const toast = useToast();
    const date = ref();
    const dsThongKe = ref([] as ThongKe[]);
    const tongTien = ref(0);
    const tyLeTrongThang = ref({} as TyLe);
    const tyLe = ref({} as TyLe);
    const loadingBar = ref(true);
    const selectDate = ref(false);

    khamSucKhoeRepository.getListThongKe()
        .then((response) => {
          dsThongKe.value = response.data;
          console.log("###########@@@@@@@@@@@@@@@@@@@ dsThongKe: " + JSON.stringify(dsThongKe.value));
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail: err.response.data,
            life: 2000
          });
        });

    khamSucKhoeRepository.getTyLeTrongThang()
        .then((response) => {
          tyLeTrongThang.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail: err.response.data,
            life: 2000
          });
        });

    const selectCalendar = () => {
      if((date.value[0] != null)&&(date.value[1] != null)) {
        selectDate.value = true;
        console.log("Date time %%#####: " + JSON.stringify(date.value));
        khamSucKhoeRepository.getTyLe(date.value[0]/1000, date.value[1]/1000)
            .then((response) => {
              tyLe.value = response.data;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail: err.response.data,
                life: 2000
              });
            })
      }};

    const clearCalendar = () => {
        // loadData();
      selectDate.value = false;
    }
    const options = computed(() => {
      const data = dsThongKe.value;
      console.log("options: " + JSON.stringify(data));
      return{
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '50%',
            endingShape: 'flat',
            dataLabels: {
              position: "top"
            },
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: '',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '20px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#263238'
          },
        },
        dataLabels: {
          offsetX: +100,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          },
          // formatter: function (val) {
          //   return new Intl.NumberFormat('vi-VN', { maximumFractionDigits: 2, style: 'currency', currency: 'VND' }).format(val);
          // }
        },
        tooltip: {
          y: {
            // formatter: function (val) {
            //   return new Intl.NumberFormat('vi-VN', { maximumFractionDigits: 2, style: 'currency', currency: 'VND' }).format(val);
            // }
          }
        },
        chart: {
          id: 'Thống_kê_doanh_thu',
          //stacked: true
        },
        labels: {
          show: false,
          // formatter: function (val) {
          //   return new Intl.NumberFormat('vi-VN', { maximumFractionDigits: 2, style: 'currency', currency: 'VND' }).format(val);
          // }
        },
        grid: {
          position: 'front'
        },
        xaxis: {
          categories: data.map(x => x.thang)
        }
      }
    })

    const series = computed(() => {
      const data = dsThongKe.value;
      console.log("series: " + JSON.stringify(data));

      return [{
        name: 'Số lượt khám năm trước',
        data: data.map(x => x.namTruoc)
      },
        {
          name: 'Số lượt khám năm nay',
          data: data.map(x => x.namNay)
        },
      ]
    })

    const seriesPie = computed(() => {
      console.log("################ seriesDonus tyLeTrongThang: " + JSON.stringify(tyLeTrongThang.value));
      return [ tyLeTrongThang.value.thanhCong, tyLeTrongThang.value.chuaGoi ]
    })

    const optionsPie = computed(() => {
      return {
        labels: ["Số lượng gởi thành công", "Số lượng chưa gởi"],
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val
          },
        }
      }
    })

    const seriesPieSelect = computed(() => {
      console.log("################ seriesDonus tyLeTrongThang: " + JSON.stringify(tyLeTrongThang.value));
      return [ tyLe.value.thanhCong, tyLe.value.chuaGoi ]
    })

    const optionsPieSelect = computed(() => {
      return {
        labels: ["Số lượng gởi thành công", "Số lượng chưa gởi"],
          colors: ['#4ec7fa', '#f67ea2'],
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val + "%"
          },
        }
      }
    })

    return {
      selectCalendar,
      date,
      clearCalendar,
      tongTien,
      loadingBar,
      options,
      series,
      seriesPie,
      optionsPie,
      seriesPieSelect,
      optionsPieSelect,
      tyLe,
      selectDate,
    }
  }

}
