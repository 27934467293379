import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../pages/LoginPage.vue';
import Home from "@/pages/Home.vue";
import RegisterPage from "@/pages/RegisterPage.vue";
import UserList from "@/pages/UserList.vue";
import UserUpdatePage from "@/pages/UserUpdatePage.vue";
import thongKeSoLieu from "@/pages/ThongKeSoLieu.vue";
import createBacSiPage from "@/pages/CreateBacSiPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/registerpage',
    name: 'registerpage',
    component: RegisterPage,
    props: true
  },
  {
    path: '/thongKeSoLieu',
    name: 'thongKeSoLieu',
    component: thongKeSoLieu,
    props: true
  },
  {
    path: '/userlist',
    name: 'userlist',
    component: UserList,
    props: true
  },
  {
    path: '/userupdate/:id',
    name: 'userupdate',
    component: UserUpdatePage,
    props: true
  },
  {
    path: '/createbacsi',
    name: 'createbacsi',
    component:createBacSiPage,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
