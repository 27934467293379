
import {computed, ref} from 'vue'
import {useToast} from "primevue/usetoast";
import AuthRepository from "@/services/AuthRepository";
import {useStore} from "vuex";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import {UserDetail} from "@/models/userDetail";
import moment from "moment";
import {UserUpdate} from "@/models/userUpdate";
import {useConfirm} from "primevue/useconfirm";
import {Khoa} from "@/models/khoa";
import KhamSucKhoeRepository from "@/services/KhamSucKhoeRepository";
import axios from "axios";

export default {
  setup() {
    const toast = useToast();
    const list = ref([] as UserDetail[]);
    const store = useStore();
    const listKhoa = ref([] as Khoa[]);
    const userUpdate = ref({} as UserUpdate);
    const error = ref(null);
    userUpdate.value.password = "";
    const password = ref("");
    const checkShow = ref(false);

      // if(!(store.state.permission == 'admin')){
      //   router.push({
      //     name: 'home'
      //   });
      // }

    const loadData = () => {
      AuthRepository.getListUser()
          .then((response) => {
            list.value = response.data;
            list.value.forEach(x => {
              userPermission.value.forEach(y => {
                if (y.value == x.permission) x.permission = y.label;
              })
            })
          })
          .catch();
    }

    loadData();

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const formatDateTime = (date) => {
      return moment(String(date)).format('DD/MM/YYYY');
    };

    const getUserById = (id) =>{
      AuthRepository.getById(id)
          .then((response) => {
            userUpdate.value = response.data;
            checkShow.value = true;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const valid = computed(()=> userUpdate.value.username && userUpdate.value.fullname);


    const userPermission = ref([
      {label: "Quản lý", value: "admin", param: 1},
      {label: "Nhân viên", value: "user", param: 2},
    ]);

    const addHours = (time) => {
      return moment(time).format('YYYY-MM-DD hh:mm:ss');
    };

    const formatDate = (time) => {
      return moment(time).format('DD-MM-YYYY');
    };

    const doUpdate = () => {
      console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222 userUpdate: " + JSON.stringify(userUpdate.value));
      if(userUpdate.value.password.length < 6 && userUpdate.value.password.length > 0)
      {
        toast.add({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Mật khẩu không hợp lệ, vui lòng nhập lại mật khẩu',
          life: 2000
        });
      }
      else {
        userUpdate.value.birthday = addHours(userUpdate.value.birthday);
        AuthRepository.updateUser(userUpdate.value)
            .then((response) => {
              console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222: " + JSON.stringify(userUpdate.value));
              toast.add({
                severity: 'success',
                summary: 'Cập nhật',
                detail: 'Cập nhật thông tin tài khoản thành công',
                life: 2000
              });
              checkShow.value = false;
              const temp = ref({} as UserUpdate);
              userUpdate.value = temp.value;
              loadData();
              // router.push({
              //   name: 'home'
              // });
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      };
    };
    const confirm = useConfirm();
    const del = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Bạn có chắc muốn xoá tài khoản này không?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-outlined',
        acceptLabel: 'Đồng ý',
        rejectLabel: 'Huỷ',
        accept: () => {
          AuthRepository.delete(userUpdate.value.id)
              .then(response => {
                toast.add({severity:'info', summary:'Confirmed', detail:'Tài khoản đã được xoá thành công', life: 3000});
                // router.push({
                //   name: 'home',
                // });
                const temp = ref({} as UserUpdate);
                userUpdate.value = temp.value;
                checkShow.value = false;
                loadData();
              })
              .finally(()=>{
              });
        },
        reject: () => {
        }
      });
    };
    KhamSucKhoeRepository.getListKhoa()
        .then((response) => {
          listKhoa.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    const debugInfo = () => {
      {
        console.log("###########$$$$$$$$$$ debugInfo: ");
        axios.get(`https://localhost:5001/api/auth/getInfoDebug`).then(response => {
          console.log("###########$$$$$$$$$$ debugInfo response: " + JSON.stringify(response.data));
          toast.add({
            severity: 'success',
            summary: 'Thành công',
            detail:'Tải ảnh lên thành công',
            life: 2000
          });
        })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      }
    };

    debugInfo();

    return {
      list,
      filters,
      clearFilter,
      initFilters,
      formatDateTime,
      getUserById,
      userUpdate,
      userPermission,
      listKhoa,
      doUpdate,
      del,
      password,
      checkShow,
      formatDate,
      valid
    }
  }
}
